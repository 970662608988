import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IntegerField from '@/shared/fields/integer-field';
import IdField from '@/shared/fields/id-field';
import EnumField from '@/shared/fields/enum-field';
import { LocalityField } from '@/shared/common-fields/locality-field';
import { CountyField } from '@/shared/common-fields/county-field';
import { VillageField } from '@/shared/common-fields/village-field';
import ComputedField from '@/shared/fields/computed-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';

const label = (name) => i18n.t(`CONTROL.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`CONTROL.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`CONTROL.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    real_estate_type: new EnumField(
        'real_estate_type',
        label('real_estate_type'),
        [
            { value: 'land', text: enumLabel('real_estate_type', 'land')},
            { value: 'land_constructions', text: enumLabel('real_estate_type', 'land_constructions')},
        ],
    ),
    county: new StringField('county', label('county')),
    county_id: CountyField.relationToOne(
        'county_id',
        label('county'),
        { autocompleteRequired: true },
        { placeholder: placeholder('county')},
        [],
        { asyncData: true }
    ),
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'territorial_administrative_unit_id',
        label('territorial_administrative_unit_id'),
        { autocompleteRequired: true, firstLetterFilter: true },
        {},
        [{
            key: 'parent_id',
            name: 'county_id'
        }],
        { asyncData: true }
    ),
    village_id: VillageField.relationToOne(
        'village_id',
        label('village'),
        { autocompleteRequired: true, enabled: false, firstLetterFilter: true },
        { placeholder: placeholder('village')},
        [{
            key: 'parent_id',
            name: 'territorial_administrative_unit_id'
        }],
        { asyncData: true }
    ),
    village: new StringField('village', label('village')),
    sector: new StringField('sector', label('sector')),
    street: new StringField('street', label('street'), { required: true }),
    street_number: new StringField('street_number', label('street_number'), { required: true }),
    postal_code: new StringField('postal_code', label('postal_code'), { required: true }),
    staircase: new StringField('staircase', label('staircase')),
    block: new StringField('block', label('block')),
    floor: new StringField('floor', label('floor')),
    apartment: new StringField('apartment', label('apartment')),
    land_area: new IntegerField('land_area', label('land_area'), {}),
    other_identification: new StringField('other_identification', label('other_identification')),
    cadastral_number: new StringField('cadastral_number', label('cadastral_number'), {}),
    address: new ComputedField('address', label('address'), (record) => {
        if (!record) return '';
        if (record.real_estate_type === 'land') {
            return `${record.county}, ${record.street} ${record.street_number}`;
        } else {
            return `${record.postal_code} ${record.locality}`;
        }
    }),
    construction_type: new StringField('construction_type', label('construction_type')),
};

export class RealtyModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
